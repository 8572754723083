/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@font-face {
  font-family: 'BrandonText-Bold';
  src: url("~src/assets/fonts/BrandonText-Bold.otf")
}

@font-face {
  font-family: 'BrandonText-Medium';
  src: url("~src/assets/fonts/BrandonText-Medium.otf")
}

@font-face {
  font-family: 'BrandonText-Light';
  src: url("~src/assets/fonts/BrandonText-Light.otf")
}

:root {
  --ion-background-color: #ffffff;
  --ion-content-color: #ffffff;
  --ion-item-background: #ffffff;
  --ion-item-color: #ffffff;
}

ion-content {
  --background: #ffffff;
  background-color: #ffffff;
  --padding-top: 16px;
  --padding-left: 16px;
  --padding-right: 16px;
  --padding-bottom: 16px;
}

ion-toolbar {
  --background: #324866;
  background-color: #324866;

  ion-buttons {
    --color: #f4960c;
    color: #f4960c;
  }

  ion-title {
    --ion-font-family: BrandonText-Bold;
    font-family: BrandonText-Bold;
    --color: #f4960c;
    color: #f4960c;
    font-size: 18px;
    letter-spacing: 1.2px;
  }
}

ion-content {
  --background: #ffffff;
  background-color: #ffffff;
}

ion-list {
  --background: #ffffff;
  background-color: #ffffff;
}

.flex-container {
  display: flex;
}

ion-item {
  --background: #ffffff;
  background-color: #ffffff;
  --color: #324866;
  color: #324866;
}

ion-text {
  font-family: BrandonText-Medium;
}

ion-label {
  font-family: BrandonText-Medium;
  --color: #324866;
  color: #324866;

  &.bold {
    font-family: BrandonText-Bold;
  }

  &.big {
    font-family: BrandonText-Bold;
    font-size: 16px;
  }

  &.small {
    font-family: BrandonText-Medium;
    font-size: 14px;
  }
}

ion-button {
  --background: #324866;
  background-color: #324866;
  --color: #f4960c;
  color: #f4960c;
  --ion-font-family: BrandonText-Bold;
  font-family: BrandonText-Bold;

  &.color-revert {
    --background: #f4960c;
    background-color: #f4960c;
    --color: #324866;
    color: #324866;
  }
}

.trailwerk-snackbar {
  --button-color:#f4960c;
  --color:#fff;
}

.trailwerk-logo {
  display: block;
  width: 50%;
  margin: 16px auto 16px auto;
}



.version {
  position: absolute;
  top: 0;
  right: 0;
  font-size: small;
  font-family: BrandonText-Medium;
}

app-trail {
  width: 100%;
}

.my-custom-class .modal-wrapper {
  height: 250px;
  position: absolute;
  display: block;
}

@media (max-width: 480px) {
  .my-custom-class .modal-wrapper {
    height: 360px;
    position: absolute;
    display: block;
  }
}


// #324866
// #f4960c
